import React from "react"
import { useSelector } from "react-redux"
import { Router } from "@reach/router"
import styled from "styled-components"
import EditStudentForm from "../components/editStudent"
import StudentsTable from "./studentsTable"
import StudentsDetailsPage from "./studentsDetailsPage"


const StudentsListComponent = props => {

  const { admin } = useSelector(state => ({

    ...state.mainReducer,
  }))

  return (
    <CustomDiv>
      <Router>
        <StudentsTable studentsList={props.studentsList} admin={admin} path="/studentsList" />
        <StudentsDetailsPage admin={admin} path="/studentsList/details" />
        <EditStudentForm admin={admin} path="/studentsList/edit" />
      </Router>
    </CustomDiv>
  )
}

export default StudentsListComponent

//styled component
const CustomDiv = styled.div`
  margin: 10px 10px 40px 10px;
  table {
    font-size: 14px;
  }
  th {
    padding-right: 2px;
  }
  th:first-child {
    padding-right: 0px;
  }
  td:first-child {
    font-size: 15px;
  }
  .gender {
    width: 4px;
  }
`
