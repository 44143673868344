import React from "react"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import EditIcon from "@material-ui/icons/Edit"
import styled from "styled-components"
import DeleteIcon from "@material-ui/icons/Delete"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { removeStudent } from "../redux/firebaseAction"
import Chip from "@material-ui/core/Chip"

const StudentsDetailsPage = props => {
  const { edu } = props.location.state
  const dispatch = useDispatch()
  const { admin } = props
  const deleteStudent = () => {
    navigate("/studentsList")
    dispatch(removeStudent(edu.candidate_no, edu.educator_email))
  }
  return (
    <table>
      <tbody>
        <CustomTr>
          <td>
            <ArrowBack onClick={() => navigate("/studentsList")} />
          </td>
          <CustomActionsTd>
            <EditIcon
              onClick={() => navigate("/studentsList/edit", { state: { edu } })}
            />
            <DeleteIcon
              onClick={() =>
                deleteStudent(edu.name_for_certificate, edu.educator_email)
              }
            />
          </CustomActionsTd>
        </CustomTr>
        <tr>
          <td>Candidate No</td>
          <td>{edu.candidate_no}</td>
        </tr>
        <tr>
          <td>First Name(s)</td>
          <td>{edu.first_name}</td>
        </tr>
        <tr>
          <td>Middle Name(s)</td>
          <td>{edu.middle_name}</td>
        </tr>

        <tr>
          <td>Family Name</td>
          <td>{edu.family_name}</td>
        </tr>
        <tr>
          <td>Name for Certificate</td>
          <td>{edu.name_for_certificate}</td>
        </tr>
        <tr>
          <td>Gender</td>
          <td>{edu.gender}</td>
        </tr>

        <tr>
          <td>Email</td>

          <td>{edu.email}</td>
        </tr>
        <tr>
          <td>Date of Birth</td>
          <td>{edu.date_of_birth}</td>
        </tr>

        <tr>
          <td>Re-sit</td>
          <td>{edu.resit ? "yes" : "no"}</td>
        </tr>
        <tr>
          <td>Tasting Section File</td>
          <td>
            {edu.tasting_section_file ? (
              <a href={edu.tasting_section_file}>Download</a>
            ) : (
              "not uploaded"
            )}
          </td>
        </tr>
        <tr>
          <td>MCQs Section File</td>
          <td>
            {edu.mcqs_section_file ? (
              <a href={edu.mcqs_section_file}>Download</a>
            ) : (
              "not uploaded"
            )}
          </td>
        </tr>
        {admin && (
          <>
            <tr>
              <CustomTd>Tasting Section Score</CustomTd>
              <CustomTd>
                <CustomChip
                  size="small"
                  label={
                    edu.tasting_section_score
                      ? edu.tasting_section_score
                      : "Pending"
                  }
                />
              </CustomTd>
            </tr>
            <tr>
              <CustomTd>MCQs Section Score</CustomTd>
              <CustomTd>
                <CustomChip
                  size="small"
                  label={
                    edu.mcqs_section_score ? edu.mcqs_section_score : "Pending"
                  }
                />
              </CustomTd>
            </tr>
            <tr>
              <CustomTd>Total Score</CustomTd>
              <CustomTd>
                <CustomChip
                  size="small"
                  label={
                    edu.total_percentage_obtained
                      ? edu.total_percentage_obtained
                      : "Pending"
                  }
                />
              </CustomTd>
            </tr>
          </>
        )}
      </tbody>
    </table>
  )
}
export default StudentsDetailsPage
//styled-components
const CustomTr = styled.tr`
  background-color: black;
  color: white;
  padding: 10px;
  span {
    margin: 30px;
  }
`
const CustomTd = styled.td`
  //background-color: #333333;
  color: black;
  padding: 10px;
  span {
    margin: 30px;
  }
`
const CustomActionsTd = styled.td`
  display: flex;
  justify-content: space-between;
`
const CustomChip = styled(Chip)`
  //background-color: #333333;
  color: white;
  padding: 10px;
  :first-child {
    background-color: black;
  }
  span {
    margin: 1px;
  }
`
