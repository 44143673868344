import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import TextField from "@material-ui/core/TextField"
import { ButtonStyled } from "./styles/styles"
import styled from "styled-components"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import Button from "@material-ui/core/Button"

import {
  addStudentFireStore}
  from "../redux/firebaseAction"

const EditStudentForm = props => {
  const { admin } = props
  const dispatch = useDispatch()
  const { edu } = props.location.state
  const { total_students } = useSelector(state => ({
    ...state.mainReducer,
    ...state.mainReducer.user.user,
  }))

  const [error, setError] = React.useState(false)
  const [form, setForm] = React.useState({
    candidate_no: edu.candidate_no
      ? edu.candidate_no
      : total_students + 1000000,
    educator_email: edu.educator_email,
    first_name: edu.first_name,
    middle_name: edu.middle_name,
    family_name: edu.family_name,
    name_for_certificate: edu.name_for_certificate,
    gender: edu.gender,
    email: edu.email,
    date_of_birth: edu.date_of_birth,
    tasting_section_score: edu.tasting_section_score,
    tasting_section_file: edu.tasting_section_file
      ? edu.tasting_section_file
      : "",
    mcqs_section_score: edu.mcqs_section_score,
    mcqs_section_file: edu.mcqs_section_file ? edu.mcqs_section_file : "",
    addedBy: "",
    resit: edu.resit,
  })
  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }
  const handleCheckbox = event => {
    setForm({ ...form, [event.target.name]: event.target.checked })
  }
  const handleFile = event => {
    setForm({ ...form, [event.target.name]: event.target.files[0] })
  }
  const Redirect = () => {
    navigate("/studentsList", { state: { edu } })
  }
  const addStudent = () => dispatch(addStudentFireStore(form))
  const handleSave = form => {
    if (form.first_name.length !== 0 && form.email.length !== 0) {
      addStudent(form)
      Redirect()
    } else {
      console.log("form fields are required")
      setError(true)
    }
  }
  const handleClose = value => {
    setForm({
      first_name: "",
      middle_name: "",
      family_name: "",
      name_for_certificate: "",
      gender: "",
      email: "",
      date_of_birth: "",
      links: "",
      keywords: "",
      addedBy: "",
      resit: false,
    })
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <table style={{ marginTop: "40px" }}>
          <tbody>
            <CustomTr>
              <td>
                <ArrowBack
                  onClick={() =>
                    navigate("/studentsList/details", { state: { edu } })
                  }
                />
              </td>
              <td></td>
            </CustomTr>
          </tbody>
        </table>
        <CustomForm>
          <form noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              name="first_name"
              error={error}
              required
              value={form.first_name}
              onChange={handleChange}
              label="First Name(s)"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="middle_name"
              error={error}
              required
              value={form.middle_name}
              onChange={handleChange}
              label="Middle Name(s)"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="family_name"
              value={form.family_name}
              onChange={handleChange}
              label="Family Name"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="name_for_certificate"
              value={form.name_for_certificate}
              onChange={handleChange}
              label="Name for Certificate"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="gender"
              value={form.gender}
              onChange={handleChange}
              label="Gender"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="email"
              value={form.email}
              onChange={handleChange}
              label="Email"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="date_of_birth"
              value={form.date_of_birth}
              onChange={handleChange}
              label="Date of Birth"
              variant="outlined"
            />
            <FormGroup row>
              <Button>Upload MCQs Sheet</Button>
              <input
                type="file"
                name="mcqs_section_file"
                id="mcqs_sheet"
                onChange={handleFile}
              />

              <FormGroup row></FormGroup>
              <Button>Upload Tasting Sheet</Button>
              <input
                type="file"
                name="tasting_section_file"
                id="tasting_sheet"
                onChange={handleFile}
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="resit"
                    checked={form.resit}
                    onChange={handleCheckbox}
                    value="checkedA"
                  />
                }
                label="Re-sit?"
              />
            </FormGroup>
            {admin && (
              <>
                <TextField
                  id="standard-basic"
                  type="number"
                  name="tasting_section_score"
                  value={form.tasting_section_score}
                  onChange={handleChange}
                  label="Tasting Section: Score"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  type="number"
                  name="mcqs_section_score"
                  value={form.mcqs_section_score}
                  onChange={handleChange}
                  label="MCQs Section: Score"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  name="educator_email"
                  placeholder="Email address of the educator NOT student"
                  value={form.educator_email}
                  onChange={handleChange}
                  label="Educator's email address"
                  variant="outlined"
                />
              </>
            )}
            <div style={{ textAlign: "right" }}>
              <ButtonStyled
                text="black"
                bg="white"
                onClick={() => handleClose()}
              >
                Cancel
              </ButtonStyled>
              <ButtonStyled onClick={() => handleSave(form)}>Save</ButtonStyled>
            </div>
          </form>
        </CustomForm>
      </MuiPickersUtilsProvider>
    </>
  )
}
export default EditStudentForm

//styled-components
const CustomForm = styled.div`
  padding-top: 50px;
  .MuiFormGroup-root {
    padding: 0 10px;
  }
  .MuiTextField-root {
    width: 100%;
    margin: 10px;
  }
  form {
    margin: 0 25%;
  }
  form label,
  input,
  h2,
  label,
  span,
  input,
  textarea {
    font-size: 16px;
    font-family: "GT-Walsheim-Regular", sans-serif;
  }
  .MuiDialog-paper {
    min-width: 20%;
  }
  svg {
    width: 0.6em;
  }
`

const CustomTr = styled.tr`
  background-color: black;
  color: white;
`
