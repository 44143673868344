import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { Link } from "@reach/router"
import Chip from "@material-ui/core/Chip"
import { CustomButton } from "../components/styles/styles"

const StudentsTable = props => {
  let {
    admin,
    allStudentsList,
  } = useSelector(state => ({
    ...state.mainReducer,
  }))
  let studentsList = admin ? allStudentsList : props.studentsList
  if (props.location.state.studentsList) {
    studentsList = props.location.state.studentsList
  }
  return (
    <>
      <table>
        <tbody>
          <CustomTr>
            <th>Candidate No</th>
            <th>Name for Certificate</th>
            <th className="gender">Gender</th>
            <th>Email</th>
            <th>Re-sit</th>
            <th>Educator's Email</th>
            {admin && (
              <>
                <CustomTh>Tasting Section</CustomTh>
                <CustomTh>MCQs Section</CustomTh>
                <CustomTh>Final Score </CustomTh>
              </>
            )}
          </CustomTr>

          {studentsList &&
            studentsList.map((edu, index) => (
              <StyledTr
                key={index}
                onClick={() =>
                  navigate("/studentsList/details", { state: { edu } })
                }
              >
                <td>{edu.candidate_no}</td>
                <td>{edu.name_for_certificate}</td>
                <td className="gender">{edu.gender}</td>
                <td>{edu.email}</td>
                <td>{edu.resit ? "Yes" : "No"}</td>
                <td>{edu.educator_email}</td>
                {admin && (
                  <>
                    <CustomTd>
                      <CustomChip
                        size="small"
                        label={
                          edu.tasting_section_score
                            ? edu.tasting_section_score
                            : "Pending"
                        }
                      />
                    </CustomTd>
                    <CustomTd>
                      <CustomChip
                        size="small"
                        label={
                          edu.mcqs_section_score
                            ? edu.mcqs_section_score
                            : "Pending"
                        }
                      />
                    </CustomTd>
                    <CustomTd>
                      <CustomChip
                        size="small"
                        label={
                          edu.total_percentage_obtained
                            ? edu.total_percentage_obtained
                            : "Pending"
                        }
                      />
                    </CustomTd>
                  </>
                )}
              </StyledTr>
            ))}
        </tbody>
      </table>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Link to="/addStudents">
              <CustomButton  pl="10px" pr="10px" color="white" bg="black">
                <span>Add a student</span>
              </CustomButton>
            </Link>
          </div>
    </>
  )
}
export default StudentsTable

const CustomTr = styled.tr`
  background-color: black;
  color: white;
  padding: 10px;
  span {
    margin: 10px;
  }
`
const StyledTr = styled.tr`
  padding: 10px;
  :hover {
    background: #f0efefa3;
    cursor: pointer;
  }
  span {
    margin: 10px;
  }
`
const CustomTh = styled.th`
  //background-color: #333333;
  color: white;
  padding: 10px;
  span {
    margin: 30px;
  }
`
const CustomTd = styled.td`
  //background-color: #333333;
  color: white;
  padding: 10px;
  :first-child {
    background-color: yellow;
  }
  span {
    margin: 30px;
  }
`
const CustomChip = styled(Chip)`
  //background-color: #333333;
  color: white;
  padding: 10px;
  :first-child {
    background-color: black;
  }
  span {
    margin: 1px;
  }
`
