import React from "react"
import Layout from "../components/layout"
// import "react-vertical-timeline-component/style.min.css"
// import "react-big-calendar/lib/css/react-big-calendar.css"

import LoginForm from "../components/loginForm"
import { useSelector } from "react-redux"
import StudentsListComponent from "../components/studentsListComponent"

const StudentsList = () => {
  const {
    email,
    studentsList,
    admin,
    allStudentsList,
  } = useSelector(state => ({
    ...state.mainReducer.user.user,
    ...state.mainReducer,
  }))
  const listOfStudents = admin ? allStudentsList : studentsList
  return (
    <>
      {email ? (
        <Layout>
          <div>
            <StudentsListComponent
              admin={admin}
              email={email}
              studentsList={listOfStudents}
            />
          </div>
        </Layout>
      ) : (
        <Login />
      )}
    </>
  )
}
export default StudentsList


const Login = () => {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  )
}
